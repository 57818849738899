var userLang = navigator.language || navigator.userLanguage;
userLang = userLang.substring(0, 2).toLowerCase();
if (!["it", "en", "fr", "de", "es"].includes(userLang)) {
  userLang = "fr";
}
window.Parsley.setLocale(userLang);

if ($("#new_user").length != 0) {
  $("#new_user").parsley();
}

window.Parsley.addValidator("rejectEmoji", {
  // requirementType: "string",
  validateString: function (value) {
    var regex = new RegExp(
      /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
    );
    return !regex.test(value);
  },
});

window.Parsley.addValidator("rejectPattern", {
  requirementType: "string",
  validateString: function (value, requirement) {
    // check that the email value does not contain http or // via regex
    var regex = new RegExp(requirement);
    return !regex.test(value);
  },
});

window.Parsley.addAsyncValidator(
  "emailUnique",
  function (xhr) {
    console.log("AsyncValidator");
    // Ritorna true anche se la richiesta non funziona per far
    // comunque andare avanti l'utente.
    // La validazione della mail c'è comunque alla fine del form.
    return xhr.status !== 200 || !xhr.responseJSON.found;
  },
  "/api/v5/users/email/check?email={value}"
);


$(function() {
  $("header.user-role-select-form .signup-steps input:submit[name!='commit']").on('click', function(event) {
    event.preventDefault();
    manageStepChange(this);
  });

  $('header.user-role-select-form .flat-box-steps-circle div').on('click', function() {
    manageStepCircles(this);
  });

  $("header.user-role-select-form .signup-steps input:submit[name='commit']").on('click', function(event) {
    event.preventDefault();
    $(this).closest('form').submit();
  });

  $('header.user-role-select-form input').on('change keyup', function() {
    manageInputChange(this);
  });

  // check every input and select in the form to enable/disable submit button on change or keyup event (for each step)
  ['adult', 'teacher'].forEach(function(role) {
    ['1', '2', '3'].forEach(function(step) {
      ['input', 'select'].forEach(function(input) {
        $('.step-' + step + '-' + role + ' ' + input).on('change keyup', function() {
          manageDisableBtn(this, parseInt(step));
        });
      });
    });
  });

  // required to avoid double-click on submit button
  $(".btn-signup-flow-last").one('click', function (event) {
    $(this).prop('disabled', true);
   });
  $(".btn-signup-flow-last").dblclick(function(e){
    e.preventDefault();
  });
});


// --- START login/sign-up flow functions ----

var getCurrentStep = window.getCurrentStep = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  return curStep;
};

var manageDisableBtn = window.manageDisableBtn = function($this, step) {
  var btn = $($this).closest('.signup-steps').find("input[type='submit']");
  var form = $('#new_user').parsley({excluded: ""})
  var filled = form.isValid({group: 'step_' + step});
  // KEEP IT FOR FUTURE DEBUGGING
  // form.fields.forEach(function(field) {
  //   if (!field.isValid({force: true}) && field.$element[0].id == "select-city") {
  //       console.log('Field pending validation:', field.$element[0].id);
  //       console.log('Error messages:', field.getErrorsMessages()[0]);
  //   }
  // });
  if (filled === true) {
    $(btn).prop('disabled', false);
  } else {
    $(btn).prop('disabled', true);
  }
};

var onPrivaciesChange = window.onPrivaciesChange = function($this, step) {
  if ($($this).is(':checked')) {
    $($this).attr('value', '1');
    $($this).attr('checked', true);
  } else {
    $($this).attr('value', '');
    $($this).attr('checked', null);
  }
  manageDisableBtn($this, step);
};

var dateSeparator = '/';

var correctCaptcha = window.correctCaptcha = function(response) {
  $('#recaptcha_status').val('1');
  var classesParent = $(".g-recaptcha").closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  manageDisableBtn(".g-recaptcha", curStep);
};

var manageStepCircles = window.manageStepCircles = function($this) {
  var currentIndex = $($this).siblings('.active').attr('class').split(' ')[0].split('-')[1];
  var selected = $($this).attr('class').split(' ')[0];
  var selectedIndex = selected.split('-')[1];
  if (selectedIndex*1 < currentIndex*1) {
    $('.flat-box.signup-steps').hide();
    $('.flat-box-header.signup-steps').hide();
    $('.flat-box-steps-circle').hide();
    $('.flat-box.signup-steps.'+ selected).show();
    $('.flat-box-header.signup-steps.'+ selected).show();
    $('.flat-box-steps-circle.'+selected).show();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
};

var manageInputChange = window.manageInputChange = function($this) {
  var step = getCurrentStep($this);
  if ($($this).is(':checkbox')) {
    if ($($this).attr('id') == 'checkbox-form-tos_agreement') {
      $('.privacies input:checkbox').prop('checked', $($this).is(':checked'));
      $('.privacies input:checkbox').each(function() {
        onPrivaciesChange(this);
      });
    } else {
      $('#checkbox-form-tos_agreement').prop('checked', false);
      $('#checkbox-form-tos_agreement').attr('value', '');
    }
    onPrivaciesChange($this, step);
  }
};

var manageStepChange = window.manageStepChange = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep = curStep*1+1;
  $('.signup-steps').hide();
  $('.flat-box-steps-circle').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    console.log('skip this step');
    nextStep = nextStep +1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $(".flat-box-steps-circle.signup-steps.step-" + nextStep + "-role-" + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

var gobackSignupFlow = window.gobackSignupFlow = function($this) {
  // event.preventDefault();
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep = curStep*1-1;
  $('.signup-steps').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    nextStep = nextStep - 1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

