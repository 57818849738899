$('.avatar').click(function() {
    $('.avatar-dropdown').toggle(500);
});

// right menu
$('.hamburger-icon').click(function() {
    $(this).toggleClass('open');
    $('.rightmenu').animate({width:'toggle'}, 350);
});


$('button.hamburger').click(function() {
    $(this).toggleClass('is-active');
    $('.rightmenu').animate({width:'toggle'}, 350);
});