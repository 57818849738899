// hide all elements with class .caps-lock-message then
// check caps lock on form group wher class .check-caps-lock is present then select the %small .caps-lock-message and show it
// do the check only on the input where the user just pressed any key
$(document).ready(function(){

  $(".caps-lock-message").hide();
  $(".check-caps-lock").keypress(function(e){
    var $this = $(this);
    var $capsLockMessage = $this.parent().find(".caps-lock-message");
    var keyCode = e.keyCode ? e.keyCode : e.which;
    var shiftKey = e.shiftKey ? e.shiftKey : ((keyCode == 16) ? true : false);
    if (((keyCode >= 65 && keyCode <= 90) && !shiftKey) || ((keyCode >= 97 && keyCode <= 122) && shiftKey)) {
      $capsLockMessage.show();
    } else {
      $capsLockMessage.hide();
    }
  });


});

