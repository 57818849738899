/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
$(document).ready(function(){
  $('#select-nation').on('change', function(){
    fill_cities($("#select-city"), this.value);
  });
});

window.fill_cities = function fill_cities(select, nation_id) {
  select.find('option').remove();
  select.append("<option value=''> </option>");
  if (nation_id && nation_id !== ""){
    var composedUrl = "/school_data/cities_list_by_country_id?country_id=" + nation_id;
    $.ajax({url: composedUrl,
      dataType: "json",
      success: function(result){
        $.each(result, function(key, value)
        {
          select.append('<option value=' + value.id + '>' + value.city_name + '</option>');
        });
    }});
  }
}