// login - sign up page
$(document).ready(function(){
    const loginPage = document.querySelector('.pages.login-signup');
    if (loginPage) {
        const btnSignIn = document.querySelector('.btn-sign-in');
        const btnSignUp = document.querySelector('.btn-sign-up');
        const signInContainer = document.querySelector('.devise-sign-in');
        const signUpContainer = document.querySelector('.devise-sign-up');
        const loginFormInner = document.querySelector('.login-form-inner');
        const images = document.querySelectorAll('.login-side-image');

        function updateHeight() {
            const biggerHeight = Math.max(signInContainer.offsetHeight, signUpContainer.offsetHeight);
            loginFormInner.style.height = `${biggerHeight}px`;

            // this needed to fix the max height of the image at the left
            const loginForm = document.querySelector('.login-form');
            images.forEach((image) => {
                image.style.maxHeight = `${loginForm.offsetHeight}px`;
            });
        }

        btnSignIn.addEventListener('click', () => {
            btnSignIn.classList.add('active');
            btnSignUp.classList.remove('active');
            signInContainer.classList.add('active');
            signUpContainer.classList.remove('active');
            updateHeight();
        });

        btnSignUp.addEventListener('click', () => {
            btnSignIn.classList.remove('active');
            btnSignUp.classList.add('active');
            signInContainer.classList.remove('active');
            signUpContainer.classList.add('active');
            updateHeight();
        });

        // Initial height setting
        updateHeight();
          
        // Create a MediaQueryList object
        var mediaquery = window.matchMedia("(max-width: 768px)")
        
        // Attach listener function on state changes
        mediaquery.addEventListener("change", function() {
            updateHeight();
        });
    }
});
